import React from 'react';
import { FormattedMessage } from 'react-intl';

export const profileData = {
    initialFields: [
        { label: <FormattedMessage id='manage.profiles.name' />, value: "profileName", type: "text", isMandatory: true },
        { label: <FormattedMessage id='manage.profiles.description' />, value: "description", type: "textarea", isMandatory: false },
        // Select HW revision first and FW version next for VC10 & UM3+ compact
        { label: <FormattedMessage id='manage.profiles.HWRevision' />, value: "hardwareList", type: "singleselect", isMandatory: false },
        { label: <FormattedMessage id='manage.profiles.zmFirmware' />, value: "firmwareList", type: "singleselect", isMandatory: false }
    ],
    zm1InitialFields: [
        { label: <FormattedMessage id='manage.profiles.name' />, value: "profileName", type: "text", isMandatory: true },
        { label: <FormattedMessage id='manage.profiles.description' />, value: "description", type: "textarea", isMandatory: false },
        // Select FW version first and HW revision next for ZM1
        { label: <FormattedMessage id='manage.profiles.zmFirmware' />, value: "firmwareList", type: "singleselect", isMandatory: false },
        { label: <FormattedMessage id='manage.profiles.HWRevision' />, value: "hardwareList", type: "singleselect", isMandatory: false }
    ],
}

export const dnpTimeArrVal = ["DnpDSTTimeOffset","DnpUTCTimeOffset","LocalTimeOffset"];

export const zm1CfciArr = ["Current-Low-Threshold","E-Field-Threshold","E-Field-Zero-Cross-Thershold","GPS-Retries","FCI-LED-Delay","FCI-LED-Duration","Fault-LED-Restore-Duration","Adaptive-E-Field-Enable","Adaptive-E-Field-Percent","Fault-Duration","Fault-Threshold","Health-Periods","Sustained-Outage-Time","Wakeup-Period","Wan-up-Time"];

export const vc10ControlDropdownList = [{ label: <FormattedMessage id='manage.profiles.control.fixedpoint' />, value: '1' }, { label: <FormattedMessage id='manage.profiles.control.powerControl' />, value: '2' }, { label: <FormattedMessage id='manage.profiles.control.auto' />, value: '3' }, { label: <FormattedMessage id='manage.profiles.control.flicker' />, value: '4' }]

export const minMaxInfo = {
    "DistLogPeriod" : "seconds",
    "AedCooldown" : "samples",
    "AedGuardBand": "samples",
    "AedWindowSize": "samples",
    "LogIIrmsThreshold2":"A",
    "LogIIrmsThreshold1":"A",
    "LogITempThreshold2":"C",
    "LogITempThreshold1":"C",
    "CndIrmsThresh":"A",
    "CndTempThresh":"C",
    "DnpDSTTimeOffset":"HHMM",
    "DnpUTCTimeOffset":"HHMM",
    "DnpStaleStackTime":"seconds",
    "EventLogPeriod":"seconds",
    "LocalTimeOffset":"HHMM",
    "FciDiDtLimit":"A",
    "FciDiDtMinDuration":"ms",
    "FciDiDtRiseTime":"ms",
    "FciLedTimeoutDuration":"seconds",
    "FciLossOfSourceFallLimitPercent":"%",
    "FciLossOfSourceFallTime":"ms",
    "FciNormalLimit":"A",
    "FciLossOfSourceMinDuration":"ms",
    "FciNormalMinDuration":"seconds",
    "FciInrushDelay":"ms",
    "FciPreInrushLowCurrentMinDuration":"ms",
    "FciPercentLimit":"%",
    "FciPercentMinDuration":"ms",
    "FciPercentMinLimit":"A",
    "FciPercentSamplePeriod":"hours",
    "FciThreshLimit":"A",
    "FciThreshMinDuration":"ms",
    "BeaconDuration":"minutes",
    "CliDuration":"minutes",
    "DistLogInterval":"hours",
    "ElogInterval":"hours",
    "FaultLEDDelay":"minutes",
    "FaultLEDDuration":"hours",
    "FaultLEDRate":"seconds",
    "FaultLEDRestoreDuration":"minutes",
    "FaultLEDRestoreRate":"seconds",
    "HiAmpTimeDuration":"minutes",
    "LogIRecordInterval":"minutes",
    "LogIReportInterval":"hours",
    "LosThreshold":"percent",
    "LosDuration":"milliseconds",
    "LosSustainedTime":"seconds",
    "LosLevel":"deci-Amps",
    "LosHistory":"milliseconds",
    "FciThresholdDuration":"milliseconds",
    "FciThresholdEnable":"A",
    "FciPercentOffset":"A",
    "FciPercentThreshold":"percent",
    "FciPercentHistory":"hours",
    "FciNormalDelay":"milliseconds",
    "FciDiDtDuration":"milliseconds",
    "FciPercentDuration":"milliseconds",
    "Current-Low-Threshold": "mA",
    "E-Field-Threshold": "V/m",
    "FCI-LED-Delay": "seconds",
    "FCI-LED-Duration": "seconds",
    "Fault-LED-Restore-Duration": "seconds",
    "Fault-Duration": "milliseconds",
    "Health-Periods": "wake up periods",
    "Sustained-Outage-Time": "seconds",
    "Wakeup-Period": "seconds",
    "Wan-up-Time": "seconds",
    "At-Floor": "A",
    "FciLossOfSourceFinalLevel": "A",
    "FciThresholdThreshold": "A",
    "LossOfEFieldAmpThreshold": "A",
    "LossOfLoadCurrentThreshold": "A",
    "LossOfEFieldMinTimeDuration": "seconds",
    "LossOfLoadMinTimeDuration": "seconds",

    // UM1 code starts here
    "cFCIDiDtlimit_lv": "A",
    "cFCIDiDtminimumduration_lv": "ms",
    "cFCIDiDtrisetime_lv": "ms",
    "cFCIDiDtlimit_mv": "A",
    "cFCIDiDtminimumduration_mv": "ms",
    "cFCIDiDtrisetime_mv": "ms",
    "cFCIlossofsourcefalllimitpercent": "%",
    "cFCIlossofsourcefalltime": "ms",
    "cFCIlossofsourcefinallevel": "A",
    "cFCIlossofsourceminimumduration": "ms",
    "cFCInormalminimumduration": "ms",
    "cFCIpercentlimit_lv": "%",
    "cFCIpercentminimumduration_lv": "ms",
    "cFCIpercentminimumlimit_lv": "A",
    "cFCIpercentsampleperiod_lv": "hours",
    "cFCIpercentlimit_mv": "%",
    "cFCIpercentminimumduration_mv": "ms",
    "cFCIpercentminimumlimit_mv": "A",
    "cFCIpercentsampleperiod_mv": "hours",
    "cFCIthresholdlimit_lv": "A",
    "cFCIthresholdminimumduration_lv": "ms",
    "cFCIthresholdlimit_mv": "A",
    "cFCIthresholdminimumduration_mv": "ms",

    "faultLEDDelay": "minutes",
    "faultLEDDuration": "hours",
    "faultLEDRate": "seconds",
    "faultLEDRestoreRate": "seconds",
    "faultLEDRestoreDuration": "minutes",
    "ledDuration": "minutes",
    "auditLogAutoGen": "minutes",
    "distLogAutoGen": "minutes",
    "eventLogAutoGen": "minutes",
    "systLogAutoGen": "minutes",
    "lineMonitorLogAutoGen": "minutes",
    "lineMonitorLogRecord": "minutes",

    "hiAmpTimeDuration_lv": "minutes",
    "hiAmpTrigger_lv": "amps",
    "hiAmpSeperation_lv": "samples",
    "hiAmpMin_lv": "samples",
    "hiAmpMax_lv": "samples",
    "hiAmpTimeDuration_mv": "minutes",
    "hiAmpTrigger_mv": "amps",
    "hiAmpSeperation_mv": "samples",
    "hiAmpMin_mv": "samples",
    "hiAmpMax_mv": "samples",
    // UM1 code ends here

    //vc10 code start here
        // -monitor tab
    "faultCurrentMinDuration_lv": "ms",
    "faultCurrentLimit_lv": "A",
    "faultCurrentLimit_mv": "A",
    "faultCurrentMinDuration_mv": "ms",
    "faultCurrentLimit_rcf_lv":"A",
    "faultCurrentLimit_rcf_mv": "A",
    "faultCurrentMinDuration_rcf_lv":"ms",
    "faultCurrentMinDuration_rcf_mv": "ms",
    "faultCurrentRiseTime_mv": "ms",
    "faultCurrentPercent_lv": "%",
    "faultCurrentPercentMinDuration_lv": "ms",
    "faultCurrentPercentMinDuration_mv": "ms",
    "faultCurrentMinLimit_lv": "A",
    "faultCurrentMinLimit_mv": "A",
    "faultCurrentSampleDuration_lv": "hours",
    "faultCurrentPercent_mv": "%",
    "faultCurrentSampleDuration_mv": "hours",
    "normalCurrentMinDuration": "ms",
    "currentFallLimit": "%",
    "currentFallTime": "ms",
    "currentFinalLevel": "A",
    "currentMinDuration": "ms",
        //visual indicator
    "lEDDuration": "minutes",
    "faultLEDBlinkRate": "seconds",
    "faultLEDResetDelay": "minutes",
    "faultLEDBlinkRate_pr": "seconds",
        // logging tab
    "logAutoGenerateInterval_al": "minutes",
    "logAutoGenerateInterval_el": "minutes",
    "logAutoGenerateInterval_sl": "minutes",
    "logAutoGenerateInterval_lml": "minutes",
    "logAutoGenerateInterval_vcl": "minutes",
    "logInterval": "minutes",
    "recordInterval": "minutes",
        //Control tab
    "voltageBandwidth_am" : "volts",
    "highVoltageSP": "volts",
    "lowVoltageSP": "volts",

    // UM3+ Compact code starts here
    "thresholdFaultCurrentLimit": "A",
    "thresholdFaultCurrentMinDuration": "ms",
    "rateOfChangeFaultCurrentLimit": "A",
    "rateOfChangeFaultCurrentMinDuration": "ms",
    "faultCurrentRiseTime": "ms",
    "faultCurrentPercent":"%",
    "faultCurrentPercentMinDuration":"ms",
    "faultCurrentMinLimit":"A",
    "faultCurrentSampleDuration": "hours",
    "logAutoGenerateInterval_dl":"minutes"
    // UM3+ Compact code ends here
    
}

export const ProfileDataOrder = {
    "MM3": {
        "cfci": {
            "headers": ["cfciDidt", "cfciLed", "cfciLossOfSource", "cfciNormal", "cfciOthers", "cfciPercent", "cfciThresh"], // here we add sorted array of subheaders in a tab

            "cfciDidt": ["FciDiDtEnabledLatch", "FciDiDtLimit", "FciDiDtMinDuration", "FciDiDtRiseTime"],
            "cfciLed": ["FciLedLatch", "FciLedStateEnabledLatch", "FciLedTimeoutDuration", "FaultLEDRate", "FaultLEDRestoreDuration", "FaultLEDDelay", "FaultLEDRestoreRate"],
            "cfciLossOfSource": ["FciLossOfSourceEnabledLatch", "FciLossOfSourceFallLimitPercent", "FciLossOfSourceFallTime", "FciLossOfSourceFinalLevel", "FciLossOfSourceMinDuration"],
            "cfciNormal": ["FciNormalLimit", "FciNormalMinDuration"],
            "cfciOthers": ["FciInrushDelay", "FciLocalAnunciatorLatch", "FciMasterEnableLatch", "FciPreInrushLowCurrentMinDuration"],
            "cfciPercent": ["FciPercentEnabledLatch", "FciPercentLimit", "FciPercentMinDuration", "FciPercentMinLimit", "FciPercentSamplePeriod"],
            "cfciThresh": ["FciThreshEnabledLatch", "FciThreshLimit", "FciThreshMinDuration"]
        },
        "nonCfci": ["CndIrmsThresh", "CndTempThresh", "CurrentDirectionInvert", "CurrentDirectionReversedEnableLatch", "DnpDSTOff", "DnpDSTOn", "DnpDSTTimeOffset", "DnpStaleStackTime", "DnpUTCTimeOffset", "EfieldNotPresentEnableLatch", "EventLogPeriod", "GpsEnableLatch", "IndLedLatch", "LocalTimeOffset", "LossOfEFieldAmpThreshold", "LossOfEFieldMinTimeDuration", "LossOfLoadCurrentThreshold", "LossOfLoadMinTimeDuration", "LossOfLoadPctHistory", "LossOfLoadPercent", "PhaseIdEnableLatch", "WanOffAlertEnableLatch"],
        "logi": ["LogIIrmsThreshold2", "LogIIrmsThreshold1", "LogITempThreshold2", "LogITempThreshold1", "LogIAlertEnableLatch", "LogILoggingEnableLatch"],
        "anomaly": ["AedEnableLatch", "DistLogPeriod", "AedCooldown", "AedGuardBand", "AedSensitivity", "AedWindowSize", "HiAmpCountThreshold", "HiAmpTimeDuration","HiAmpXCorrelationTemplate"],
    },
    "MM3ai": {
        "cfci": {
            "headers": ["cfciDidt", "cfciLed", "cfciLossOfSource", "cfciNormal", "cfciOthers", "cfciPercent", "cfciThresh"], // here we add sorted array of subheaders in a tab

            "cfciDidt": ["FciDiDtEnabledLatch", "FciDiDtLimit", "FciDiDtMinDuration", "FciDiDtRiseTime"],
            "cfciLed": ["FciLedLatch", "FciLedStateEnabledLatch", "FciLedTimeoutDuration", "FaultLEDRate", "FaultLEDRestoreDuration", "FaultLEDDelay", "FaultLEDRestoreRate"],
            "cfciLossOfSource": ["FciLossOfSourceEnabledLatch", "FciLossOfSourceFallLimitPercent", "FciLossOfSourceFallTime", "FciLossOfSourceFinalLevel", "FciLossOfSourceMinDuration"],
            "cfciNormal": ["FciNormalLimit", "FciNormalMinDuration"],
            "cfciOthers": ["FciInrushDelay", "FciLocalAnunciatorLatch", "FciMasterEnableLatch", "FciPreInrushLowCurrentMinDuration"],
            "cfciPercent": ["FciPercentEnabledLatch", "FciPercentLimit", "FciPercentMinDuration", "FciPercentMinLimit", "FciPercentSamplePeriod"],
            "cfciThresh": ["FciThreshEnabledLatch", "FciThreshLimit", "FciThreshMinDuration"]
        },
        "nonCfci": ["CndIrmsThresh", "CndTempThresh", "CurrentDirectionInvert", "CurrentDirectionReversedEnableLatch", "DnpDSTOff", "DnpDSTOn", "DnpDSTTimeOffset", "DnpStaleStackTime", "DnpUTCTimeOffset", "EfieldNotPresentEnableLatch", "EventLogPeriod", "GpsEnableLatch", "IndLedLatch", "LocalTimeOffset", "LossOfEFieldAmpThreshold", "LossOfEFieldMinTimeDuration", "LossOfLoadCurrentThreshold", "LossOfLoadMinTimeDuration", "LossOfLoadPctHistory", "LossOfLoadPercent", "PhaseIdEnableLatch", "WanOffAlertEnableLatch"],
        "logi": ["LogIIrmsThreshold2", "LogIIrmsThreshold1", "LogITempThreshold2", "LogITempThreshold1", "LogIAlertEnableLatch", "LogILoggingEnableLatch"],
        "anomaly": ["AedEnableLatch", "DistLogPeriod", "AedCooldown", "AedGuardBand", "AedSensitivity", "AedWindowSize", "HiAmpCountThreshold", "HiAmpTimeDuration","HiAmpXCorrelationTemplate"],
    },
    "UM3+": {
        "deviceData": ["AuditLogHistory", "AuditLogInterval", "BeaconDuration", "BeaconEnable", "CliDisable", "CliDuration", "DistLogInterval", "ElogGenerate", "ElogInterval", "FaultLEDDelay", "FaultLEDDuration", "FaultLEDIntensity", "FaultLEDRate", "FaultLEDRestoreDuration", "FaultLEDRestoreRate", "FaultLedDisable", "GroundFaultThreshold", "HiAmpCountThreshold", "HiAmpTimeDuration", "LogGenerate", "LogIRecordInterval", "LogIReportInterval", "Timezone"],
        "conductorData": ["FciDiDtEnable", "FciDiDtLimit", "FciDiDtRiseTime", "FciDiDtDuration", "FciNormalDelay", "FciPercentEnable", "FciPercentHistory", "FciPercentOffset", "FciPercentThreshold", "FciPercentDuration", "FciThresholdEnable", "FciThresholdThreshold", "FciThresholdDuration", "IDirectionEnable", "IDirectionReverse", "LosEnable", "LosHistory", "LosLevel", "LosSustainedTime", "LosThreshold", "LosDuration"],
        "conductorDataP2": ["FciDiDtEnable", "FciDiDtEnable", "FciDiDtLimit", "FciDiDtRiseTime", "FciDiDtDuration", "FciNormalDelay", "FciPercentEnable", "FciPercentHistory", "FciPercentOffset", "FciPercentThreshold", "FciPercentDuration", "FciThresholdEnable", "FciThresholdThreshold", "FciThresholdDuration", "IDirectionEnable", "IDirectionReverse", "LosEnable", "LosHistory", "LosLevel", "LosSustainedTime", "LosThreshold", "LosDuration"],
        "conductorDataP3": ["FciDiDtEnable", "FciDiDtEnable", "FciDiDtLimit", "FciDiDtRiseTime", "FciDiDtDuration", "FciNormalDelay", "FciPercentEnable", "FciPercentHistory", "FciPercentOffset", "FciPercentThreshold", "FciPercentDuration", "FciThresholdEnable", "FciThresholdThreshold", "FciThresholdDuration", "IDirectionEnable", "IDirectionReverse", "LosEnable", "LosHistory", "LosLevel", "LosSustainedTime", "LosThreshold", "LosDuration"],
        "conductorDataP4": ["FciDiDtEnable", "FciDiDtEnable", "FciDiDtLimit", "FciDiDtRiseTime", "FciDiDtDuration", "FciNormalDelay", "FciPercentEnable", "FciPercentHistory", "FciPercentOffset", "FciPercentThreshold", "FciPercentDuration", "FciThresholdEnable", "FciThresholdThreshold", "FciThresholdDuration", "IDirectionEnable", "IDirectionReverse", "LosEnable", "LosHistory", "LosLevel", "LosSustainedTime", "LosThreshold", "LosDuration"],
        "faultDetection": {
            "thresholdFault": ["enableThresholdFault", "thresholdFaultCurrentLimit", "thresholdFaultCurrentMinDuration"],
            "rateOfChangeFault": ["enableRateOfChangeFault", "rateOfChangeFaultCurrentLimit", "rateOfChangeFaultCurrentMinDuration", "faultCurrentRiseTime"],
            "percentChangeFault": ["enablePercentageChangeFault", "faultCurrentPercent", "faultCurrentPercentMinDuration", "faultCurrentMinLimit", "faultCurrentSampleDuration"],
            "faultRestoration": ["normalCurrentMinDuration"],
            "lossOfSource": ["enableLossOfSource", "currentFallLimit", "currentFallTime", "currentFinalLevel", "currentMinDuration"]
          },
        "visualIndicator":{
            "faultLED" : ["enableFaultLED", "lEDDuration", "faultLEDDelay", "faultLEDDuration", "faultLEDIntensity", "faultLEDBlinkRate"],
            "postRestoration": [ "faultLEDResetDelay", "faultLEDBlinkRate_pr"]
        },
        "logging": {
            "auditLog": ["enableAuditLog", "logAutoGenerateInterval_al", "logQuota_al"],
            "disturbanceLog": ["enabledisturbanceLog", "logAutoGenerateInterval_dl", "logQuota_dl"],
            "eventLog": ["enableEventLog", "logAutoGenerateInterval_el", "logQuota_el"],
            "systemLog": ["enableSystemLog", "logAutoGenerateInterval_sl", "logQuota_sl"],
            "lineMonitorLog": ["enableLineMonitorLog", "logAutoGenerateInterval_lml", "logQuota_lml", "logInterval"],
            "waveforms": ["enableDisturbanceWaveform","enableFaultWaveform","enableInterruptionWaveform"]
        },
        "advanceSettings": {
            "gps": ["gpsEnable"],
            "timezone": ["timezone"],
            "network": [ "pdpContext", "cid", "pdpType", "apn", "pdpAddress", "dataCompression", "headerCompression"],
            "brokerDetails": ["brokerIPAddress", "brokerTCPPort", "commonName", "enableTLS", "keepAlive"],
            "anolmalyEventDetector": ["hiAmpEnable", "hiAmpCountThreshold", "hiAmpTimeDuration", "hiAmpTrigger", "hiAmpSeparation", "hiAmpMin", "hiAmpMax"]
        }
    },
    "ZM1": {
        "zmNetwork" : {
            "headers":["Access-Point-Name","DNP Master IP Address","DNP-Address","DNP-Port","Master-Address","Master-Port","Tls-Enable","Tls-Commom-Name","Tls-Configuration","agw1","agw2"],
            
            "agw1": ["AGW1-Apn","AGW1-Ip","AGW1-Port","AGW1-Addr"],
            "agw2": ["AGW2-Apn","AGW2-Ip","AGW2-Port","AGW2-Addr"]
        },
        "zmCfci": ["Current-Low-Threshold","E-Field-Threshold","E-Field-Zero-Cross-Thershold","GPS-Retries","FCI-LED-Delay","FCI-LED-Duration","Fault-LED-Restore-Duration","Adaptive-E-Field-Enable","Adaptive-E-Field-Percent","Fault-Duration","Fault-Threshold","Health-Periods","Sustained-Outage-Time","Wakeup-Period","Wan-up-Time"],
        "zmCfciData": [],
        "zmNetworkData": [],
        "anomalyData": []
    },
    "UM1": {
        "cfci": {
            "headers": ["cFCIDiDt_lv", "cFCIDiDt_mv", "cFCIpercent_lv", "cFCIpercent_mv", "cFCIthreshold_lv", "cFCIthreshold_mv", "cFCInormal", "cFCIlossofsource"], // here we add sorted array of subheaders in a tab

            "cFCIDiDt_lv": ["cFCIDiDtenabledlatch_lv", "cFCIDiDtlimit_lv", "cFCIDiDtminimumduration_lv", "cFCIDiDtrisetime_lv"],
            "cFCIDiDt_mv": ["cFCIDiDtenabledlatch_mv", "cFCIDiDtlimit_mv", "cFCIDiDtminimumduration_mv", "cFCIDiDtrisetime_mv"],
            "cFCIlossofsource": ["cFCIlossofsourceenabledlatch", "cFCIlossofsourcefalllimitpercent", "cFCIlossofsourcefalltime", "cFCIlossofsourcefinallevel", "cFCIlossofsourceminimumduration"],
            "cFCInormal": ["cFCInormalminimumduration"],
            "cFCIpercent_lv": ["cFCIpercentenabledlatch_lv", "cFCIpercentlimit_lv", "cFCIpercentminimumduration_lv", "cFCIpercentminimumlimit_lv", "cFCIpercentsampleperiod_lv"],
            "cFCIpercent_mv": ["cFCIpercentenabledlatch_mv", "cFCIpercentlimit_mv", "cFCIpercentminimumduration_mv", "cFCIpercentminimumlimit_mv", "cFCIpercentsampleperiod_mv"],
            "cFCIthreshold_lv": ["cFCIthresholdenabledlatch_lv", "cFCIthresholdlimit_lv", "cFCIthresholdminimumduration_lv"],
            "cFCIthreshold_mv": ["cFCIthresholdenabledlatch_mv", "cFCIthresholdlimit_mv", "cFCIthresholdminimumduration_mv"],
        },
        "nonCfci": {
            "gpsEnableLatch": ["gpsEnableLatch"],
            "timezone": ["timezone"],
            "visualIndicator": ["faultLEDDelay", "faultLEDDuration", "faultLEDIntensity", "faultLEDRate", "faultLEDRestoreDuration", "faultLEDRestoreRate", "disableFaultLEDLatch", "ledDuration"],
            "logs": ["auditLogAutoGen", "auditLogQuota", "auditLogEnable", "distLogAutoGen", "distLogQuota", "distLogEnable", "eventLogAutoGen", "eventLogQuota", "eventLogEnable", "systLogAutoGen", "systLogQuota", "systLogEnable"],
            "lineMonitor": ["lineMonitorLogAutoGen", "lineMonitorLogQuota", "lineMonitorLogRecord", "lineMonitorLogEnable"],
            "waveforms": ["enableDistWaveform", "enableFaultWaveform", "enableInterruptWaveform"]
        },
        "anomaly": {
            "hiAmp_LV": ["hiAmpEnable_lv", "hiAmpCntThreshold_lv", "hiAmpTimeDuration_lv", "hiAmpTrigger_lv", "hiAmpSeperation_lv", "hiAmpMin_lv", "hiAmpMax_lv"],
            "hiAmp_MV": ["hiAmpEnable_mv", "hiAmpCntThreshold_mv", "hiAmpTimeDuration_mv", "hiAmpTrigger_mv", "hiAmpSeperation_mv", "hiAmpMin_mv", "hiAmpMax_mv"]
        },
        "um1Network": {
            "brokerDetails": ["brokerIPAddress", "brokerTCPPort", "commonName", "enableTLS", "keepAlive"],
            "pdpContext": ["cid", "pdpType", "apn", "pdpAddress", "defaultAPN", "dataCompression", "headerCompression"]
        }
    },

    "VC10": {
        "faultDetection": {
            "thresholdFault": ["enableThresholdFault_lv", "faultCurrentLimit_lv", "faultCurrentMinDuration_lv", "enableThresholdFault_mv", "faultCurrentLimit_mv", "faultCurrentMinDuration_mv"],
            "rateOfChangeFault": ["enableRateOfChangeFault_lv", "faultCurrentLimit_rcf_lv", "faultCurrentMinDuration_rcf_lv", "faultCurrentRiseTime_lv", "enableRateOfChangeFault_mv", "faultCurrentLimit_rcf_mv", "faultCurrentMinDuration_rcf_mv", "faultCurrentRiseTime_mv"],
            "percentChangeFault": ["enablePercentageChangeFault_lv", "faultCurrentPercent_lv", "faultCurrentPercentMinDuration_lv", "faultCurrentMinLimit_lv", "faultCurrentSampleDuration_lv",  "enablePercentageChangeFault_mv", "faultCurrentPercent_mv", "faultCurrentPercentMinDuration_mv", "faultCurrentMinLimit_mv", "faultCurrentSampleDuration_mv"],
            "faultRestoration": ["normalCurrentMinDuration"],
            "lossOfSource": ["enableLossOfSource", "currentFallLimit", "currentFallTime", "currentFinalLevel", "currentMinDuration"]
          },
        "visualIndicator":{
            "faultLED" : ["enableFaultLED", "lEDDuration", "faultLEDDelay", "faultLEDDuration", "faultLEDIntensity", "faultLEDBlinkRate"],
            "postRestoration": [ "faultLEDResetDelay", "faultLEDBlinkRate_pr"]
        },
        "logging": {
            "eventLog": ["enableEventLog", "logQuota_el", "logAutoGenerateInterval_el"],
            "auditLog": ["enableAuditLog", "logQuota_al", "logAutoGenerateInterval_al"],
            "systemLog": ["enableSystemLog", "logQuota_sl", "logAutoGenerateInterval_sl"],
            "lineMonitorLog": ["enableLineMonitorLog", "logQuota_lml", "logAutoGenerateInterval_lml", "logInterval"],
            "varControlLog": ["enableVarControlLog", "logAutoGenerateInterval_vcl", "recordInterval"]
        },
        "advanceSettings": {
            "gps": ["gpsEnable"],
            "timezone": ["timezone"],
            "network": [ "pdpContext", "cid", "pdpType", "apn", "pdpAddress", "dataCompression", "headerCompression"],
            "brokerDetails": ["brokerIPAddress", "brokerTCPPort", "commonName", "enableTLS", "keepAlive"]
        },
        "control": {
            "varControlEnable": ["varControlEnable"],
            "varControlOperatingMode": ["varControlOperatingMode"],
            "autoMode":[ "voltageBandwidth_am",  "highVoltageSP", "lowVoltageSP"],
            "controlToggle": ["scheduleToggle", "oddToggle"],
        }
    }
}

export const getSortedList = (currentData, requiredData, deviceType) => {
    if (!requiredData) {
        return currentData
    } else {
        return requiredData.reduce((accumulator, value) => {
            if (currentData.hasOwnProperty(value)) return ({ ...accumulator, [value]: currentData[value] })
            else return { ...accumulator }
        }, {});
    }
}